import React, { useState } from "react";
import styled from "styled-components";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";

import packageJSON from "../../../package.json";

import CoinsTable from "./CoinsTable";

import Fullpage from "../../components/Fullpage/Fullpage";
import ButtonSwitch from "../../components/ButtonSwitch/ButtonSwitch";
import LinkButton from "../../components/LinkButton/LinkButton";

import useLockBodyScroll from "../../hooks/useLockBodyScroll";
import { CoinsData, CoinsInfo } from "../../store/reducers/coins";
import { Button, Checkbox, Desktop, GroupBox, Monitor, Radio } from "react95";

type Props = {
  data: (CoinsInfo[string] & CoinsData[string])[] | null;
  showingFollowing: boolean;
  showFollowing: () => void;
  showTop: () => void;
};
const DashboardLayout = ({
  data,
  showingFollowing,
  showFollowing,
  showTop,
  history,
}: Props & RouteComponentProps<{}>) => {
  useLockBodyScroll();
  const location = useLocation();
  const [galleryType, setGalleryType] = useState<"drinking" | "nieznane" | "coding" | "fighter" | "rockstar">(
    "drinking",
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setGalleryType(e.target.value as any);

  return (
    <Fullpage>
      <Header>
        <h1>
          Rozak
          <small>v30</small>
        </h1>
      </Header>
      <div>
        <GroupBox label="Choose your Rafal:">
          <Radio
            checked={galleryType === "drinking"}
            onChange={handleChange}
            value="drinking"
            label="Drinking Rafal"
            name="drinking"
          />
          <br />
          <Radio
            checked={galleryType === "rockstar"}
            onChange={handleChange}
            value="rockstar"
            label="Rockstar Rafal"
            name="rockstar"
          />
          <br />
          <Radio
            checked={galleryType === "coding"}
            onChange={handleChange}
            value="coding"
            label="Coding Rafal"
            name="coding"
          />
          <br />
          <Radio
            checked={galleryType === "fighter"}
            onChange={handleChange}
            value="fighter"
            label="Rafal fighter"
            name="coding"
          />
          <br />
          <Radio
            checked={galleryType === "nieznane"}
            onChange={handleChange}
            value="nieznane"
            label="??? "
            name="nieznane"
          />
        </GroupBox>
      </div>

      <div>
        <br />
        <Button fullWidth primary onClick={() => history.push(`/news/${galleryType}`)}>
          <img src="images/gallery.png" style={{ margin: "5px" }} />
          PLAY
        </Button>
      </div>
    </Fullpage>
  );
};

export default withRouter(DashboardLayout);

let Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 5px;
  margin-bottom: 1em;
  /* background: rgb(128, 128, 128); */

  h1 {
    /* background: -webkit-linear-gradient(transparent, black); */
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    font-size: 1.8rem;
    font-weight: bold;
    /* font-family: arial; */
    font-style: italic;
    color: ${({ theme }) => theme.borderDark};
    text-shadow: 2px 2px white;
    span {
      /* font-weight: 100; */
    }
    small {
      font-size: 0.6em;
      color: black;
      font-weight: 100;
      text-shadow: 1px 1px white;

      color: ${({ theme }) => theme.borderDark};
      text-decoration: none;
      /* font-style: normal; */
      margin-left: 0.5rem;
    }
  }
`;

let CoinsTableWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  & > div {
    height: 100%;
  }
`;

const CenteredDesktop = styled(Desktop)`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

const Rose = styled.div``;
