import React from "react";
import styled from "styled-components";

import { Gallery } from "./Gallery";

const Layout = (props: any) => {
  return (
    <Wrapper>
      <Gallery type={props.match.params.type} />
    </Wrapper>
  );
};

export default Layout;

let Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;
