import React, { useState } from "react";
import { withRouter } from "react-router";
import { Button } from "react95";

export const Gallery = function ({ type }: { type: string }) {
  const [index, setIndex] = useState(0);
  const maxIndex = (images as any)[type].length - 1;

  const path = `/images/rafal/${(images as any)[type][index]}`;

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Button
          style={{ width: "50%" }}
          onClick={() => {
            if (index !== 0) setIndex(index - 1);
          }}
        >
          {"<<<"}
        </Button>
        <Button
          style={{ width: "50%" }}
          onClick={() => {
            if (index != maxIndex) setIndex(index + 1);
          }}
        >
          {">>>"}
        </Button>
      </div>
      <img src={path} style={{ width: "100%" }} />
    </div>
  );
};

const images = {
  coding: ["coding/1.jpeg", "coding/2.jpeg", "coding/3.jpeg", "coding/4.jpeg", "coding/5.jpeg"],
  drinking: ["drinking/1.jpeg", "drinking/2.jpeg", "drinking/3.jpeg", "drinking/4.jpeg", "drinking/5.jpeg"],
  fighter: ["fighter/1.jpeg"],
  nieznane: [
    "nieznane/1.png",
    "nieznane/2.png",
    "nieznane/3.jpeg",
    "nieznane/4.jpeg",
    "nieznane/5.jpeg",
    "nieznane/6.jpeg",
    "nieznane/7.jpeg",
  ],
  rockstar: ["rockstar/1.jpeg", "rockstar/2.jpeg"],
};
